'use client';

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { TNextClientComponent } from 'types/next';
import { useTranslation } from 'hooks/useTranslation';
import Icon from 'components/custom/Icon';
import Container from 'components/tailwind-catalyst/Container';
import { styles } from '.';
type TProps = {
  banner: {
    __typename: string;
    id: string;
    content: string;
    url: string;
    isExternal: boolean;
  };
  lang: string;
};
const Banner: TNextClientComponent<TProps> = props => {
  const {
    banner,
    lang
  } = props;
  const {
    t
  } = useTranslation(lang);
  const pathname = usePathname();
  if (pathname.includes('/tiny-houses')) {
    return;
  }
  const children = <>
      <div className={styles.content}>
        <p>{banner?.content}</p>
        <button className="font-semibold group-hover:underline pl-2 flex flex-row items-center gap-x-1" aria-disabled="true">
          {t('label.custom.readMore')}
          <Icon accessor="arrow-right" className="text-sm" size="12px" />
        </button>
      </div>
    </>;
  const handleGenerateComponent = () => {
    let result;
    if (banner?.url.startsWith('http') || banner?.url.startsWith('www')) {
      result = <a href={banner?.url} target={banner?.isExternal ? '_blank' : ''}>
          {children}
        </a>;
    }
    if (banner?.url.startsWith('/')) {
      result = <Link href={`/${lang}${banner?.url}`}>{children}</Link>;
    }
    return result;
  };
  if (banner === null) {
    return;
  }
  return <div className={styles.container} aria-disabled="true" data-sentry-component="Banner" data-sentry-source-file="Banner.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Banner.tsx">{handleGenerateComponent()}</Container>
    </div>;
};
export default Banner;